<!-- =========================================================================================
    File Name: Manual.vue
    Description: Knowledge Base Category Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="manual-page">
    <div class="vx-row mt-8 match-height">
      <div
        v-for="section in sections"
        :key="section.id"
        class="vx-col w-full sm:w-1/2 md:w-1/3"
      >
        <vx-card v-if="section.isShow" class="mb-base">
          <h4 class="mb-4">{{ section.title }}</h4>
          <vs-divider />
          <ul class="list-dot pl-6">
            <li 
              v-for="que in section.files" 
              :key="que.title" 
              class="py-2">
              <a target="_blank" :href="`${imageUrl}/${que.url}`">{{ que.title }}</a>
            </li>
          </ul>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import env from "@/env"

export default {
  components: {},
  data() {
    return {
      categoryTitle: 'Category Name',
      categorySubtitle:
        'petiole antimasquer nonenduring hoofish unbed anergic sweetwood ailsyte.',
      sections: [
        {
          id: 0,
          title: 'โครงการ',
          isShow: true,
          files: [
            {
              id: 0,
              title: 'คู่มือการ upload รูปภาพ',
              url: 'documentations/manual/cms/คู่มือการuploadรูปภาพ_e0vdi8.pdf',
            },
            {
              id: 1,
              title: 'คู่มือการใช้งานระบบบ้านพร้อมขาย',
              url: 'documentations/manual/cms/คู่มือการใช้งานบ้านพร้อมขาย_ihlmyu.pdf',
            },
          ],
        },
        {
          id: 1,
          title: 'แบรนด์',
          isShow: false,
          files: [
            {
              id: 0,
              title: 'Dessert halvah carrot cake sweet?',
              url: '/other/knowledge-base/category/title',
            }
          ],
        },
        {
          id: 2,
          title: 'ระบบจัดการข้อมูลแบบบ้าน',
          isShow: false,
          files: [
            {
              id: 0,
              title: 'Oat cake lemon drops sweet sweet?',
              url: '/other/knowledge-base/category/title',
            }
          ],
        },
        {
          id: 3,
          title: 'โปรโมชัน',
          isShow: true,
          files: [
            {
              id: 0,
              title: 'คู่มือการสร้างโปรโมชัน',
              url: 'documentations/manual/cms/คู่มือการสร้างโปรโมชั่น_ccf8fv.pdf',
            }
          ],
        },
        {
          id: 4,
          title: 'ตั้งค่าอื่นๆ',
          isShow: false,
          files: [
            {
              id: 0,
              title: 'Macaroon tootsie roll?',
              url: '/other/knowledge-base/category/title',
            }
          ],
        },
        {
          id: 5,
          title: 'Landing Pages',
          isShow: false,
          files: [
            {
              id: 0,
              title: 'Brownie dessert gummies?',
              url: '/other/knowledge-base/category/title',
            }
          ],
        },
        {
          id: 6,
          title: 'LH Content',
          isShow: false,
          files: [
            {
              id: 0,
              title: 'Brownie dessert gummies?',
              url: '/other/knowledge-base/category/title',
            }
          ],
        },
        {
          id: 7,
          title: 'SEO',
          isShow: false,
          files: [
            {
              id: 0,
              title: 'Brownie dessert gummies?',
              url: '/other/knowledge-base/category/title',
            }
          ],
        },
        {
          id: 8,
          title: 'Redirection',
          isShow: false,
          files: [
            {
              id: 0,
              title: 'Brownie dessert gummies?',
              url: '/other/knowledge-base/category/title',
            }
          ],
        },
        {
          id: 9,
          title: 'Banner',
          isShow: false,
          files: [
            {
              id: 0,
              title: 'Brownie dessert gummies?',
              url: '/other/knowledge-base/category/title',
            }
          ],
        },
        {
          id: 10,
          title: 'Highlight',
          isShow: false,
          files: [
            {
              id: 0,
              title: 'Brownie dessert gummies?',
              url: '/other/knowledge-base/category/title',
            }
          ],
        },
        {
          id: 11,
          title: 'Footer',
          isShow: false,
          files: [
            {
              id: 0,
              title: 'Brownie dessert gummies?',
              url: '/other/knowledge-base/category/title',
            }
          ],
        },
        {
          id: 12,
          title: 'ผู้ใช้งานระบบ',
          isShow: true,
          files: [
            {
              id: 0,
              title: 'คู่มือการเปลี่ยนรหัสผ่าน',
              url: 'documentations/manual/cms/คู่มือการเปลี่ยนรหัสผ่าน_iacwxz.pdf',
            }
          ],
        },
        {
          id: 13,
          title: 'กลุ่มผู้ใช้งานระบบ',
          isShow: false,
          files: [
            {
              id: 0,
              title: 'Brownie dessert gummies?',
              url: '/other/knowledge-base/category/title',
            }
          ],
        },
      ],
    }
  },
  computed: {
    imageUrl() {
      const baseFolder = env.VUE_APP_CLOUDINARY_DEFAULT_FOLDER
      const baseImageUrl = env.VUE_APP_IMAGE_URL
      return `${baseImageUrl}/${baseFolder}`
    }
  },
  mounted() {
    this.$emit('changeRouteTitle', 'คู่มือการใช้งานระบบ')
  },
  methods: {},
}
</script>

<style lang="scss">
  .list-dot {
    list-style-type: circle;
  }
</style>